import { API, post } from 'api/base';

/**
 * [POST /dev/scores]
 *
 * {
 *   matchId: STRING,
 *   notificationId: STRING,
 *   action: 'approved' | 'rejected',
 *  }
 *
 * Post new scores
 *
 */

const postScoreConfirm = ({ matchId, notificationId, action }) => {
  const endpointDomain = process.env.REACT_APP_API_DOMAIN;

  return post(API(endpointDomain, `dev/match/confirmation`, { action }), {
    match_id: matchId,
    notification_id: notificationId,
  });
};

export default postScoreConfirm;
