import SignupForm from 'components/layout/SignupForm';
import Profile from 'components/Profile';
import { useEffect } from 'react';
import useUser from 'store/user';

export default function SignupPage() {
  const { state, setLogout } = useUser();

  const { user } = state;

  useEffect(() => {
    if (!user) return;

    if (user.status === 'guest') return;

    setTimeout(() => {
      setLogout();
    }, 500);
  }, [user, setLogout]);

  if (!user || user.status !== 'guest') return null;

  return (
    <div className="h-full">
      <div className="h-14 bg-white shadow grid content-center px-5">
        <h1 className="text-2xl font-bold">PocketPro</h1>
      </div>

      <div className="-mt-14 pt-20 h-full flex flex-col p-5">
        <Profile user={user} className="h-full max-h-80" />

        <SignupForm user={user} className="flex flex-col justify-center items-center" />
      </div>
    </div>
  );
}
