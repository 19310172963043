import { Navigate } from 'react-router-dom';
import ErrorPage from './page/ErrorPage';
import SignupPage from './page/SignupPage';
import SignInPage from './page/SignInPage';
import BoardPage from './page/BoardPage';
import EnterScorePage from './page/EnterScorePage';

const raw = [
  {
    path: `/`,
    element: <Navigate to="/sign-in" replace />,
  },
  {
    path: `/sign-in`,
    element: <SignInPage />,
  },
  {
    path: `/sign-up`,
    element: <SignupPage />,
  },
  // {
  //   path: `/logout`,
  //   element: <LogoutPage />,
  // },
  {
    path: `/board`,
    element: <BoardPage />,
  },
  {
    path: `/view-rating`,
    element: null,
  },
  {
    path: `/enter-score`,
    element: <EnterScorePage />,
  },
  {
    path: `*`,
    element: <Navigate to="/sign-in" replace />,
  },
];

const routes = raw.map((route) => {
  return { ...route, errorElement: <ErrorPage /> };
});

export default routes;
