import deleteFavorite from 'api/favorite/deleteFavorite';
import postFavoriteData from 'api/favorite/postFavorite';
import ArrowDoubleIcon from 'components/icons/ArrowDoubleIcon';
import HeartIcon from 'components/icons/HeartIcon';
import StartIcon from 'components/icons/StartIcon';
import TrashIcon from 'components/icons/TrashIcon';
import { errorNotify, isError } from 'functions/tool';
import useUI from 'store/ui';
import useUser from 'store/user';

const Card = ({ opponent, onSubmit, onFavorite, isFavorite }) => {
  return (
    <div className="p-4 border border-gray-300 flex flex-col space-y-2">
      <div className="w-full flex justify-between items-center">
        <p className="text-xl font-semibold">{opponent.username}</p>

        <button
          type="button"
          className=" text-center text-black  mb-2"
          onClick={() => onFavorite(opponent, isFavorite ? 'delete' : 'add')}
        >
          {isFavorite ? <TrashIcon.Outline /> : <HeartIcon.Outline />}
        </button>
      </div>

      <div className="w-full flex justify-between items-end">
        <div className="flex space-x-4">
          <p className="flex items-center space-x-2">
            <ArrowDoubleIcon.Up className="size-5" />
            <span className="font-semibold">{opponent.player_level}</span>
          </p>

          <p className="flex items-center space-x-2">
            <StartIcon.Outline className="size-5" />
            <span className="font-semibold">{opponent.rating}</span>
          </p>
        </div>

        <button
          type="button"
          className="p-2 text-center border border-black bg-black text-white"
          onClick={() => onSubmit(opponent)}
        >
          Enter Score
        </button>
      </div>
    </div>
  );
};

export default function OpponentCard({ searchResult, setOpponent, favoriteList = [], research }) {
  const { state, getFavoriteList } = useUser();

  const { notify, loader } = useUI();

  const onSubmitHandler = (opponentInfo) => {
    setOpponent(opponentInfo);
  };

  const onFavoriteHandler = (opponentInfo, type) => {
    if (type === 'add') onAddFavoriteHandler(opponentInfo);
    if (type === 'delete') onDeleteFavoriteHandler(opponentInfo);
  };

  const onAddFavoriteHandler = (opponentInfo) => {
    loader.on();
    postFavoriteData({ player_id: state.user.id, favorite_id: opponentInfo.id })
      .then((result) => {
        if (isError(result)) throw result;

        notify.on({ type: 'ok', title: '', message: result });
        getFavoriteList(state.user.id);
        if (searchResult.id === opponentInfo.id) research();
      })
      .catch((error) => errorNotify(notify, error))
      .finally(() => loader.off());
  };

  const onDeleteFavoriteHandler = (opponentInfo) => {
    loader.on();
    deleteFavorite({ player_id: state.user.id, favorite_id: opponentInfo.player_id })
      .then((result) => {
        if (isError(result)) throw result;

        notify.on({ type: 'ok', title: '', message: result });
        getFavoriteList(state.user.id);
      })
      .catch((error) => errorNotify(notify, error))
      .finally(() => loader.off());
  };

  return (
    <div className="mt-4">
      {searchResult?.status === '404' && (
        <p className="mb-4 text-gray-600">
          No result found for <span className="font-semibold">"{searchResult.username}"</span>
        </p>
      )}

      {searchResult?.status === '200' && (
        <div className="flex flex-col space-y-4 mb-4">
          <h2 className="font-semibold text-2xl">Result</h2>
          <Card opponent={searchResult} onFavorite={onFavoriteHandler} onSubmit={onSubmitHandler} />
        </div>
      )}

      {favoriteList.length > 0 && (
        <div className="flex flex-col space-y-4 border-t pt-4">
          <h2 className="font-semibold text-2xl">Favorite</h2>

          {favoriteList.map((favorite, index) => (
            <Card
              key={`favorite-opponent-${index}`}
              opponent={favorite}
              onFavorite={onFavoriteHandler}
              onSubmit={onSubmitHandler}
              isFavorite
            />
          ))}
        </div>
      )}
    </div>
  );
}

// const mockData = [
//   {
//     rating: 850,
//     player_level: 'Pro',
//     username: 'pool_master.99',
//     matches_played: 45,
//     pool_hall: 'Jack Sangenjaya',
//     email: 'poolmaster99@example.com',
//     name: 'John Smith',
//   },
//   {
//     rating: 730,
//     player_level: 'A',
//     username: 'cue_king',
//     matches_played: 32,
//     pool_hall: 'Jack Sangenjaya',
//     email: 'cueking@example.com',
//     name: 'Michael Davis',
//   },
//   {
//     rating: 560,
//     player_level: 'SA',
//     username: 'eight_ball_ace',
//     matches_played: 22,
//     pool_hall: 'Jack Sangenjaya',
//     email: 'eightballace@example.com',
//     name: 'Alice Johnson',
//   },
//   {
//     rating: 400,
//     player_level: 'B',
//     username: 'rack.em.up',
//     matches_played: 18,
//     pool_hall: 'Jack Sangenjaya',
//     email: 'rackemup@example.com',
//     name: 'Tom Lee',
//   },
//   {
//     rating: 250,
//     player_level: 'SB',
//     username: 'pocket.wizard',
//     matches_played: 10,
//     pool_hall: 'Jack Sangenjaya',
//     email: 'pocketwizard@example.com',
//     name: 'Emily Clark',
//   },
//   {
//     rating: 150,
//     player_level: 'C',
//     username: 'cue_ball_master',
//     matches_played: 5,
//     pool_hall: 'Jack Sangenjaya',
//     email: 'cueballmaster@example.com',
//     name: 'David Martinez',
//   },
// ];
