import getNotification from 'api/notification/getNotification';
import MobileFrame from 'components/layout/MobileFrame';
import { errorNotify, isError } from 'functions/tool';
import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import useUI from 'store/ui';
import useUser from 'store/user';

import NotificationReducer from './reducer';

const initialState = { notification: undefined };

export const UserContext = createContext();

export function NotificationContextProvider(props) {
  const { notify } = useUI();

  const UserProvider = useUser();

  const id = UserProvider.state.user.id;

  const [state, dispatch] = useReducer(NotificationReducer, initialState);

  const { notification } = state;

  const checkNotification = useCallback(() => {
    if (!id) return;
    getNotification(id)
      .then((notificationList) => {
        if (isError(notificationList)) throw notificationList;
        dispatch({ type: 'SET_NOTIFICATION', payload: notificationList });
      })
      .catch((error) => {
        errorNotify(notify, error);
        dispatch({ type: 'SET_NOTIFICATION', payload: [] });
      });
  }, [id, notify]);

  const value = useMemo(
    () => ({
      state,
      checkNotification,
    }),
    [state, checkNotification]
  );

  return (
    <UserContext.Provider value={value}>
      <MobileFrame>{props.children}</MobileFrame>
    </UserContext.Provider>
  );
}

export default function useNotification() {
  const context = useContext(UserContext);
  if (context === null) {
    throw new Error('wrap parent component');
  }
  return context;
}
