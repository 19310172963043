/* eslint-disable react-hooks/exhaustive-deps */
import postPlayerData from 'api/player/postPlayerData';
import clsx from 'clsx';
import Buttons from 'components/element/Buttons';
import LoadingSVG from 'components/element/LoadingSVG';
import { errorNotify, isError } from 'functions/tool';
import { validateUsername } from 'functions/user';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useData from 'store/data';
import useUI from 'store/ui';
import useUser from 'store/user';
import TitleBox from './TitleBox';

function PlayerClass({ className, user }) {
  const navigate = useNavigate();

  const { setUser } = useUser();

  const { notify, loader } = useUI();

  const dataProvider = useData();

  const dataState = dataProvider.state;

  const { getPoolHallList } = dataProvider;

  const { playerLevels, poolHalls } = dataState;

  const [form, setForm] = useState({
    username: '',
    player_level: playerLevels[0].value,
    pool_hall: '',
  });

  const [error, setError] = useState({ username: undefined });

  const onChange = (target, value) => {
    if (target === 'username') setError({ username: undefined });
    setForm({ ...form, [target]: value });
  };

  useEffect(() => {
    if (!poolHalls) getPoolHallList();
  }, [getPoolHallList, poolHalls]);

  const onSubmit = () => {
    loader.on();
    postPlayerData({
      ...user,
      ...form,
    })
      .then((result) => {
        if (isError(result)) throw result;
        setUser({ ...user, ...result, status: 'user' });
        navigate('/board');
      })
      .catch((error) => {
        if (error.message.includes('Email already exists')) {
          setError({ username: 'Email already exists' });
          return;
        }
        errorNotify(notify, error);
      })
      .finally(() => loader.off());
  };

  const checkUserName = () => {
    const result = validateUsername(form.username);

    if (result === true) return;

    setError({ username: result });
  };

  return (
    <div className={className}>
      <TitleBox title="Email">
        <p className="p-2 border rounded bg-gray-50 text-gray-500">{user.email}</p>
      </TitleBox>

      <TitleBox title="Username">
        <div className="flex flex-col">
          <input
            type="text"
            className={clsx('p-2 border rounded bg-white', error.username ? 'border-red-500' : '')}
            value={form.username}
            onChange={(e) => onChange('username', e.target.value)}
            onBlur={(e) => checkUserName(e.target.value)}
            placeholder="Enter a username"
          />
          {error.username && <span className="pl-2 text-sm text-red-500">{error.username}</span>}
        </div>
      </TitleBox>

      <TitleBox title="Player Level">
        <select
          className="p-2 border rounded bg-white"
          onChange={(e) => onChange('player_level', e.target.value)}
        >
          {playerLevels.map((level) => (
            <option key={level.value} value={level.value}>
              {level.label}
            </option>
          ))}
        </select>
      </TitleBox>

      <TitleBox title="Pool Hall Member">
        {!poolHalls && <LoadingSVG size="w-6 h-6" />}

        {poolHalls && (
          <select
            className="p-2 border rounded bg-white"
            onChange={(e) => onChange('pool_hall', e.target.value)}
          >
            <option value="">N/A</option>
            {poolHalls.map((item, index) => (
              <option key={`${item}-${index}`} value={item}>
                {item}
              </option>
            ))}
          </select>
        )}
      </TitleBox>

      <di className="w-full my-4">
        <Buttons.LoadingButton
          className={clsx(
            'w-full text-white',
            error.username ? 'bg-gray-400' : 'bg-black hover:bg-gray-800'
          )}
          loaderClassName="w-full"
          onClick={onSubmit}
          disabled={error.username}
        >
          Submit
        </Buttons.LoadingButton>
      </di>
    </div>
  );
}

export default PlayerClass;
