// import * as path from 'path';
import axios from 'axios';
import { getAuthToken } from 'functions/user';

const source = axios.CancelToken.source();

export const instance = axios.create({
  maxRedirects: 0,
});

const responseBody = (response) => response.data;

export function API(endpoint, endpointPath, query = undefined) {
  const url = new URL(`./${endpointPath}`, endpoint);
  const newQuery = query;

  if (newQuery !== undefined)
    Object.entries(newQuery)
      .filter(([, value]) => value !== undefined)
      .forEach(([key, value]) => url.searchParams.append(key, String(value)));

  return url.toString();
}

function errorStatus(err, req) {
  if (err?.code === 'ERR_CANCELED') {
    return {
      endpoint: req,
      success: false,
      status: 'CANCELED',
      message: JSON.stringify(err.message),
    };
  }

  if (err.response)
    return {
      endpoint: req,
      success: false,
      status: err.response.status,
      message: JSON.stringify(err.response.data),
    };

  if (err.request)
    return {
      endpoint: req,
      success: false,
      request: err.request,
      message: JSON.stringify(err.message),
      status: 500,
    };
  // console.log(err.message);

  return {
    endpoint: req,
    success: false,
    message: { error: err.message },
  };
}

const defaultHeader = {
  'Content-Type': 'application/json',
  // Pragma: 'no-cache',
  // Expires: '-1',
  // accept: 'application/json',
  // 'Referrer-Policy': 'no-referrer',
};

export function cancelAPI() {
  source.cancel('Operation canceled by the user.');
}

export function get(req, headers = {}, headless = false) {
  const token = getAuthToken();
  return instance
    .get(req, {
      cancelToken: source.token,
      headers: headless
        ? {}
        : {
            ...defaultHeader,
            ...headers,
            Authorization: `Bearer ${token}`,
          },
    })
    .then(responseBody)
    .catch((err) => errorStatus(err, req, instance));
}

export function post(req, body, headers = {}) {
  const token = getAuthToken();
  return (
    instance
      .post(req, body, {
        headers: {
          ...defaultHeader,
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      // .then(status)
      .then(responseBody)
      .catch((err) => errorStatus(err, req))
  );
}

export function postStatus(req, body, headers = {}) {
  const token = getAuthToken();
  return (
    instance
      .post(req, body, {
        headers: {
          ...defaultHeader,
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      // .then(status)

      .then(responseBody)
      .catch((err) => errorStatus(err, req))
  );
}

export function postForm(req, body, headers = {}) {
  const token = getAuthToken();
  return instance
    .post(req, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(responseBody)
    .catch((err) => errorStatus(err, req));
}

export function postPure(req, body, headers = {}) {
  const token = getAuthToken();
  return instance.post(req, body, {
    headers: {
      ...defaultHeader,
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  });
}

export function postMs(req, body, headers = {}) {
  const token = getAuthToken();
  return instance.post(req, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  });
}

export function put(req, body, headers = {}) {
  const token = getAuthToken();
  return instance
    .put(req, body, {
      headers: {
        ...defaultHeader,
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(responseBody)
    .catch((err) => errorStatus(err, req));
}

export function patch(req, body, headers = {}) {
  const token = getAuthToken();
  return instance
    .patch(req, body, {
      headers: {
        ...defaultHeader,
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(responseBody)
    .catch((err) => errorStatus(err, req));
}

export function patchStatus(req, body, headers = {}) {
  const token = getAuthToken();
  return instance
    .patch(req, body, {
      headers: {
        ...defaultHeader,
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(responseBody)
    .catch((err) => errorStatus(err, req));
}

export function requestDelete(req, headers = {}) {
  const token = getAuthToken();
  return instance
    .delete(req, {
      headers: {
        ...defaultHeader,
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(responseBody)
    .catch((err) => errorStatus(err, req));
}

export function requestDeleteWithPayload(req, body = {}) {
  const token = getAuthToken();
  return instance
    .delete(req, {
      headers: {
        ...defaultHeader,
        Authorization: `Bearer ${token}`,
      },
      data: body,
    })
    .then(responseBody)
    .catch((err) => errorStatus(err, req));
}

export function mockAwait(duration, target) {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (target) console.log(target);
      resolve();
    }, duration);
  });
}
