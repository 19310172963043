import getPlayerData from 'api/player/getPlayerData';
import clsx from 'clsx';
import LoadingSVG from 'components/element/LoadingSVG';
import ClearIcon from 'components/icons/ClearIcon';
import SearchIcon from 'components/icons/SearchIcon';
import { errorNotify, isError } from 'functions/tool';
import { useState } from 'react';
import useUI from 'store/ui';
import useUser from 'store/user';

export default function SearchUser({ setSearchResult, research }) {
  const { state } = useUser();

  const { user } = state;

  const UIProvider = useUI();

  const { notify } = UIProvider;

  const [searchName, setSearchName] = useState('');

  const [isLoading, setLoading] = useState(false);

  const onChangeHandler = (value) => {
    research();
    setSearchName(value);
  };

  const inputKeyHandler = (e) => {
    if (e.key === 'Enter') searchOpponentHandle();
  };

  const searchOpponentHandle = () => {
    if (searchName === user.username) {
      notify.on({ type: 'error', message: 'Can not set yourself as an opponent.' });
      return;
    }

    setLoading(true);
    getPlayerData({ username: searchName })
      .then((result) => {
        if (isError(result)) throw result;

        setSearchResult({ status: '200', ...result });
      })
      .catch((error) => {
        if (error.message.includes('found')) {
          setSearchResult({ status: '404', username: searchName });
          return;
        }

        errorNotify(notify, error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="w-full flex space-x-2 p-2 pl-3 bg-gray-50 border rounded">
      <input
        type="text"
        className="w-full bg-transparent focus:outline-none"
        value={searchName}
        placeholder="Search opponent's username"
        onChange={(e) => onChangeHandler(e.target.value)}
        onKeyDown={inputKeyHandler}
      />

      {!isLoading && (
        <button
          type="button"
          className={clsx('text-gray-500 hover:text-gray-800', !searchName && 'hidden')}
          onClick={() => onChangeHandler('')}
        >
          <ClearIcon.Basic />
        </button>
      )}

      {!isLoading && (
        <button
          type="button"
          className={clsx(
            'px-1',
            !searchName ? 'text-gray-300' : 'text-gray-500 hover:text-gray-800 '
          )}
          disabled={!searchName}
          onClick={searchOpponentHandle}
        >
          <SearchIcon />
        </button>
      )}

      {isLoading && <LoadingSVG size="size-5" />}
    </div>
  );
}
