import getPoolHall from 'api/poolHall/getPoolHall';
import { errorNotify, isError } from 'functions/tool';
import { createContext, useContext, useReducer } from 'react';
import useUI from 'store/ui';
import DataReducer from './resucer';

const playerLevelOptions = [
  { value: 'Pro', label: 'Pro' },
  { value: 'SA', label: 'SA' },
  { value: 'A', label: 'A' },
  { value: 'SB', label: 'SB' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'Beginner', label: 'Beginner' },
];

const initialState = { playerLevels: playerLevelOptions, poolHalls: undefined };

export const DataContext = createContext();

export function DataContextProvider(props) {
  const { notify } = useUI();

  const [state, dispatch] = useReducer(DataReducer, initialState);

  const { poolHalls } = state;

  const getPoolHallList = () => {
    if (poolHalls) return;

    getPoolHall()
      .then((list) => {
        if (isError(list)) throw list;

        dispatch({ type: 'SET_POOL_HALL', payload: list });
      })
      .catch((error) => {
        errorNotify(notify, error);
        dispatch({ type: 'SET_POOL_HALL', payload: [] });
      });
  };

  const value = {
    state,
    getPoolHallList,
  };

  return <DataContext.Provider value={value}>{props.children}</DataContext.Provider>;
}

export default function useData() {
  const context = useContext(DataContext);
  if (context === null) {
    throw new Error('wrap parent component');
  }
  return context;
}
