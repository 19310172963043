import { API, get } from 'api/base';
import { getAuthToken } from 'functions/user';

const mock = {
  email: 'sherinlin1738@gmail.com',
  name: 'Sherin Lin',
  first_name: 'Sherin',
  last_name: 'Lin',
  username: 'sherin-test',
  pool_hall: 'Kou Hoaloha',
  player_level: 'SB',
  rating: 0,
  matches_played: 0,
};

/**
 * [GET /dev/accounts]
 *
 * { email: STRING }
 *
 * Get player information
 *
 */

const getPlayerData = ({ email, username }) => {
  const endpointDomain = process.env.REACT_APP_API_DOMAIN;
  const token = getAuthToken();

  const conditions = {};

  if (email) conditions.email = email;

  if (username) conditions.username = username;

  // await mockAwait(500);
  // return undefined;

  return get(API(endpointDomain, `dev/account`, conditions));
};

export default getPlayerData;
