import postScores from 'api/scores/postScores';
import Buttons from 'components/element/Buttons';
import { errorNotify, isError } from 'functions/tool';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUI from 'store/ui';
import useUser from 'store/user';

const ScoreBox = ({ title, value, onChange }) => {
  return (
    <div>
      <p className="font-semibold mb-2">{title}</p>
      <input
        type="number"
        className="w-full h-20 text-center border text-3xl border-black rounded"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default function EnterScores({ opponent, research }) {
  const navigate = useNavigate();

  const { state } = useUser();

  const { user } = state;

  const { notify, loader } = useUI();

  const [scores, setScores] = useState({ score_a: null, score_b: null });

  const onSubmit = () => {
    loader.on();

    postScores({ id_a: user.id, id_b: opponent.id, ...scores })
      .then((result) => {
        if (isError(result)) throw result;

        notify.on({ type: 'ok', title: '', message: result });
        navigate('/board');
      })
      .catch((error) => errorNotify(notify, error))
      .finally(() => loader.off());
  };

  return (
    <div className="p-4">
      <div className="flex items-center space-x-2 mt-8 mb-10">
        <ScoreBox
          title="Your score"
          value={scores.score_a || ''}
          onChange={(e) => setScores({ ...scores, score_a: e.target.value })}
        />

        <p className="text-xl pt-8">vs</p>

        <ScoreBox
          title={opponent.username + ' score'}
          value={scores.score_b || ''}
          onChange={(e) => setScores({ ...scores, score_b: e.target.value })}
        />
      </div>

      <Buttons.LoadingButton className="w-full bg-black text-white" onClick={onSubmit}>
        Submit
      </Buttons.LoadingButton>
    </div>
  );
}
