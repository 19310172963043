import { googleLogout } from '@react-oauth/google';
import clsx from 'clsx';
import useUser from 'store/user';

function Profile({ className, user }) {
  const { setLogout } = useUser();

  const handleLogout = () => {
    googleLogout();
    setLogout();
  };

  return (
    <div className={clsx('w-full flex items-center justify-center', className)}>
      <div className="h-full max-h-52 flex flex-col justify-evenly items-center">
        <img src={user.picture} className="rounded-full w-16 sm:w-28" alt="User Profile" />

        <h2 className="text-xl">Welcome, {user.username}</h2>

        <button className="px-2 py-1 border border-black rounded" onClick={handleLogout}>
          Sign out
        </button>
      </div>
    </div>
  );
}

export default Profile;
