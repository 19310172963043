import SystemNotify from 'components/element/Modal/SystemNotify';
import MobileFrame from 'components/layout/MobileFrame';
import { createContext, useContext, useMemo, useReducer } from 'react';

import UIReducer from './reducer';

const initialState = { notify: { isOpen: false }, loading: false };

export const UserContext = createContext();

export function UIContextProvider(props) {
  const [state, dispatch] = useReducer(UIReducer, initialState);

  const { notify } = state;

  const value = useMemo(
    () => ({
      state,
      loader: {
        on: () => dispatch({ type: 'LOADER_ON' }),
        off: () => dispatch({ type: 'LOADER_OFF' }),
      },
      notify: {
        on: ({ type, title, message, action }) =>
          dispatch({ type: 'NOTIFY_ON', payload: { type, title, message, action } }),
        off: () => dispatch({ type: 'NOTIFY_OFF' }),
      },
    }),
    [state]
  );

  return (
    <UserContext.Provider value={value}>
      <MobileFrame>{props.children}</MobileFrame>

      <SystemNotify notify={notify} onClose={() => dispatch({ type: 'NOTIFY_OFF' })} />
    </UserContext.Provider>
  );
}

export default function useUI() {
  const context = useContext(UserContext);
  if (context === null) {
    throw new Error('wrap parent component');
  }
  return context;
}
