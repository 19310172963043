import Board from 'components/Board';
import { Navigate } from 'react-router-dom';
import useUser from 'store/user';
import Main from './Main';

export default function BoardPage() {
  const { state } = useUser();

  const { user } = state;

  if (!user) return null;

  if (user.status === 'log-out') return <Navigate to="/sign-in" replace />;

  return (
    <Main canGoBack={false}>
      <Board user={user} />
    </Main>
  );
}
