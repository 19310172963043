import { API, post } from 'api/base';

/**
 * [POST /dev/scores]
 *
 * {
 *   email_a: STRING,
 *   email_b: STRING,
 *   score_a: NUMBER,
 *   score_b: NUMBER,
 *  }
 *
 * Post new scores
 *
 */

const postScores = (query) => {
  const endpointDomain = process.env.REACT_APP_API_DOMAIN;

  const { id_a, id_b, score_a, score_b } = query;

  return post(API(endpointDomain, `dev/match/record`), {
    player_a_id: id_a,
    player_b_id: id_b,
    score_a: Number(score_a),
    score_b: Number(score_b),
  });
};

export default postScores;
