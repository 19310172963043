import NotificationDrawer from 'components/layout/NotificationDrawer';
import UserDrawer from 'components/element/UserDrawer';
import ArrowIcon from 'components/icons/ArrowIcon';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useNotification from 'store/notification';
import useUser from 'store/user';

export default function Main({ children, back, canGoBack = true }) {
  const navigate = useNavigate();

  const { state, setLogout } = useUser();

  const { checkNotification } = useNotification();

  const { user } = state;

  useEffect(() => {
    checkNotification();
  }, [checkNotification]);

  useEffect(() => {
    const expireTime = user?.exp;
    if (!expireTime) {
      setLogout();
      return;
    }

    const remainingTime = expireTime * 1000 - Date.now();

    const timeout = setTimeout(() => {
      alert('Please log in again.');
      setLogout();
    }, remainingTime);
    return () => clearTimeout(timeout);
  }, [user?.exp, setLogout]);

  const onClickHandler = () => {
    if (!back) {
      navigate(-1);
      return;
    }

    back();
  };

  return (
    <>
      <div className="w-full h-16 bg-white shadow flex justify-between items-center px-5 z-9999">
        <div className="flex items-center">
          {canGoBack && (
            <button
              type="button"
              className="w-10 pr-4 text-gray-500 hover:text-gray-800"
              onClick={onClickHandler}
            >
              <ArrowIcon.Left />
            </button>
          )}

          {!canGoBack && <div className="w-10 h-1" />}

          <h1 className="text-2xl font-bold">PocketPro(Beta)</h1>
        </div>

        <div className="flex space-x-4 items-center">
          <NotificationDrawer />

          <UserDrawer
            button={
              <img
                className="w-8 h-8 bg-gray-500 rounded-full"
                alt="user icon"
                src={state.user.picture}
              />
            }
            user={state.user}
          />
        </div>
      </div>

      <div className="h-screen pt-16 -mt-16 w-full overflow-y-scroll">{children}</div>
    </>
  );
}
