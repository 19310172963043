import { API, get } from 'api/base';
/**
 * [GET /dev/accounts]
 *
 * { email: STRING }
 *
 * Get player information
 *
 */

const getFavorite = (id) => {
  const endpointDomain = process.env.REACT_APP_API_DOMAIN;

  // await mockAwait(500);
  // return undefined;

  return get(API(endpointDomain, `dev/favorite`, { player_id: id }));
};

export default getFavorite;
