import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="py-10 px-2 flex flex-col items-center space-y-4">
      <h1 className="text-lg font-semibold">Oops!</h1>

      <p>Sorry, an unexpected error has occurred.</p>

      <p className="text-center p-2 border bg-gray-50">
        <i>{error?.statusText || error?.message}</i>
      </p>
    </div>
  );
}
