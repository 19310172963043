import CheckIcon from 'components/icons/CheckIcon';
import ClearIcon from 'components/icons/ClearIcon';
import WarningCircleIcon from 'components/icons/WarningCircleIcon';
import WarningTriangleIcon from 'components/icons/WarningTriangleIcon';
import Modal from '.';
import Buttons from '../Buttons';

export default function SystemNotify({ notify, onClose }) {
  const onCloseHandler = () => {
    if (notify.action) notify.action();
    onClose();
  };

  if (!notify.isOpen) return null;

  return (
    <Modal className="z-9999" open isCloseButton={false}>
      <div className="flex flex-col items-center space-y-2">
        {notify.type === 'ok' && <CheckIcon.Outline className="text-green-600 size-14" />}

        {notify.type === 'info' && <WarningCircleIcon.Outline className="text-blue-600 size-14" />}

        {notify.type === 'warning' && (
          <WarningTriangleIcon.Outline className="text-orange-400 size-14" />
        )}

        {notify.type === 'error' && <ClearIcon.CircleOutline className="text-red-600 size-14" />}

        <p className="font-medium text-lg">{notify?.title}</p>

        <p className="pb-4">{notify?.message}</p>

        <Buttons.Basic className="w-full text-center bg-black text-white" onClick={onCloseHandler}>
          Close
        </Buttons.Basic>
      </div>
    </Modal>
  );
}
