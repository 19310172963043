import { API, get } from 'api/base';

const toPoolHall = (data) => {
  if (!data) return null;

  return data.pool_hall;
};

/**
 * [GET /dev/pool_halls]
 *
 * {  }
 *
 * Get pool hall list
 *
 */

export default function getPoolHall() {
  const endpointDomain = process.env.REACT_APP_API_DOMAIN;

  return get(API(endpointDomain, `dev/pool_hall`));
}
