import { GoogleOAuthProvider } from '@react-oauth/google';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { DataContextProvider } from 'store/data';
import { UIContextProvider } from 'store/ui';
import { UserContextProvider } from './store/user';
import routes from 'routes';
import { NotificationContextProvider } from 'store/notification';

const router = createBrowserRouter(routes);

const withAppProviders = (Component) => (props) => {
  function WrapperComponent() {
    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
        <UIContextProvider>
          <DataContextProvider>
            <UserContextProvider>
              <NotificationContextProvider>
                <RouterProvider router={router}>
                  <Component {...props} />
                </RouterProvider>
              </NotificationContextProvider>
            </UserContextProvider>
          </DataContextProvider>
        </UIContextProvider>
      </GoogleOAuthProvider>
    );
  }

  return WrapperComponent;
};

export default withAppProviders;
