import EnterScores from 'components/layout/EnterScore/EnterScores';
import OpponentCard from 'components/layout/EnterScore/OpponentCard';
import SearchUser from 'components/layout/EnterScore/SearchUser';
import { useEffect, useState } from 'react';
import useUser from 'store/user';
import Main from './Main';

export default function EnterScorePage() {
  const [searchResult, setSearchResult] = useState(undefined);

  const [opponent, setOpponent] = useState(undefined);

  const { state, getFavoriteList } = useUser();

  const { user, favoriteList } = state;

  const research = () => {
    if (searchResult) setSearchResult(undefined);
    if (opponent) setOpponent(undefined);
  };

  useEffect(() => {
    if (!user?.id || favoriteList) return;

    getFavoriteList(user.id);
  }, [getFavoriteList, user, favoriteList]);

  return (
    <Main back={opponent ? () => setOpponent(undefined) : undefined}>
      {!opponent && (
        <div className="flex flex-col p-4">
          <SearchUser setSearchResult={setSearchResult} research={research} />

          <OpponentCard
            searchResult={searchResult}
            setOpponent={setOpponent}
            favoriteList={favoriteList}
            research={research}
          />
        </div>
      )}

      {opponent && <EnterScores opponent={opponent} research={research} />}
    </Main>
  );
}
