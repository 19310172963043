import { Link } from 'react-router-dom';
import StartIcon from './icons/StartIcon';
// import ArrowLongIcon from './icons/ArrowLongIcon';
import Buttons from './element/Buttons';

function Board({ user }) {
  return (
    <div className="w-full p-5 pb-16 h-full flex flex-col">
      <h2 className="text-2xl leading-10">
        <span> Welcome back,</span>
        <br />
        <span className="font-bold">{user.username}!</span>
      </h2>

      <div className="mt-7 mb-4 p-4 rounded-2xl shadow-lg rating-card text-white">
        <p className="mb-3">You're rated at</p>
        <p className="my-7 font-bold text-5xl w-full flex items-center justify-center">
          <span className="mr-4">
            <StartIcon.Outline />
          </span>
          <span>{user.rating || 0}</span>
        </p>
      </div>

      {/* <div className="flex justify-end pr-4 mb-8">
        <Link to={`/view-rating`}>
          <button type="button" className="flex items-center">
            <span className="pt-1 font-semibold">View ratings</span>
            <span className="size-4 ml-2">
              <ArrowLongIcon.Right />
            </span>
          </button>
        </Link>
      </div> */}

      <Link to={`/enter-score`}>
        <Buttons.Basic className="bg-black hover:bg-gray-800 text-white w-full mt-4">
          Enter new score
        </Buttons.Basic>
      </Link>
    </div>
  );
}

export default Board;
