import MatchConfirmation from './MatchConfirmation';
import MatchVerification from './MatchVerification';

export default function Card({ notification }) {
  if (!notification) return null;

  if (notification.type === 'match_verification')
    return <MatchVerification notification={notification} />;

  if (notification.type === 'match_confirmation')
    return <MatchConfirmation notification={notification} />;
  return null;
}
