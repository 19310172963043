import { useState } from 'react';
import clsx from 'clsx';
import { googleLogout } from '@react-oauth/google';
import ClearIcon from 'components/icons/ClearIcon';
import useUser from 'store/user';
import MailIcon from 'components/icons/MailIcon';
import CueIcon from 'components/icons/CueIcon';
import ArrowDoubleIcon from 'components/icons/ArrowDoubleIcon';
import StartIcon from 'components/icons/StartIcon';

const IconInfoCard = ({ icon, title, value }) => {
  return (
    <div className="flex items-center space-x-4 mb-5 px-2">
      <p className="text-gray-400">{icon}</p>
      <div>
        <p className="text-gray-500 text-sm mb-1 font-light">{title}</p>
        <p>{value}</p>
      </div>
    </div>
  );
};

export default function UserDrawer({ button, user }) {
  const { setLogout } = useUser();

  const [open, setOpen] = useState(false);

  const logoutHandler = () => {
    setOpen(false);
    googleLogout();
    setLogout();
  };

  return (
    <>
      <button type="button" onClick={() => setOpen(true)}>
        {button}
      </button>

      <div
        className={clsx(
          'fixed top-0 right-0 z-50 h-screen p-4 overflow-y-auto bg-white w-80 dark:bg-gray-800 transition-transform',
          open ? 'shadow-2xl' : 'translate-x-full shadow-none'
        )}
      >
        <button
          type="button"
          onClick={() => setOpen(false)}
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <ClearIcon.Basic className="size-6" />
          <span className="sr-only">Close menu</span>
        </button>

        <div className="flex flex-col pt-4 mb-10">
          <div className="flex flex-col items-center">
            <img
              className="w-16 h-16 bg-gray-500 rounded-full my-4"
              alt="user icon"
              src={user.picture}
            />

            <p className="text-lg font-semibold">{user.username}</p>

            <button className="mt-5 px-6 py-2  bg-black text-white rounded" onClick={logoutHandler}>
              Sign out
            </button>
          </div>

          <hr className="my-5" />

          <IconInfoCard
            icon={<StartIcon.Outline className="size-4" />}
            title="Rating"
            value={<span className="text-2xl font-semibold">{user.rating}</span>}
          />

          <IconInfoCard
            icon={<MailIcon.Outline className="size-4" />}
            title="Email"
            value={user.email}
          />

          <IconInfoCard
            icon={<ArrowDoubleIcon.Up className="size-4" />}
            title="Player Level"
            value={user.player_level}
          />

          <IconInfoCard
            icon={<CueIcon className="size-4" />}
            title="Pool Hall"
            value={user.pool_hall_name}
          />
        </div>
      </div>
    </>
  );
}
