import clsx from 'clsx';

export default function TitleBox({ className, title, children }) {
  return (
    <div className={clsx('w-full flex flex-col space-y-2 mb-4', className)}>
      <p className=" text-gray-800">{title}</p>
      {children}
    </div>
  );
}
