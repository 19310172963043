import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode'; // To decode Google token
import useUser from 'store/user';

function GoogleLoginButton() {
  const { setPlayerData } = useUser();

  const onSuccess = (response) => {
    localStorage.setItem(
      'user',
      JSON.stringify({
        credential: response.credential,
        expire: new Date().getTime() + 3600000,
      })
    );

    const decoded = jwtDecode(response.credential);

    setPlayerData(decoded);
  };

  const onError = () => {
    console.log('Login Failed');
  };

  return (
    <GoogleLogin
      theme="outline"
      size="medium"
      text="large"
      locale="en"
      onSuccess={onSuccess}
      onError={onError}
      useOneTap
    />
  );
}

export default GoogleLoginButton;
