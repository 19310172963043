import { API, post } from 'api/base';

/**
 * [POST /dev/accounts]
 *
 * { favorite_id: STRING,
 *   player_id: STRING,
 *  }
 *
 * Add a new favorite player
 *
 */

const postFavoriteData = ({ player_id, favorite_id }) => {
  const endpointDomain = process.env.REACT_APP_API_DOMAIN;

  return post(API(endpointDomain, 'dev/favorite'), {
    player_id,
    favorite_id,
  });
};

export default postFavoriteData;
