import { API, get } from 'api/base';

const mock = [
  {
    notification_id: '0949cebe-d679-4a26-b32b-49475aa8c09d',
    type: 'match_verification',
    notification_body: {
      match_id: 'aab633df-fc77-42f1-a9a0-8e5781efb113',
      message: 'Confirm Match: You scored 2-1 against sherin0202 (Sherin Test Roby).',
    },
    status: 'UNREAD',
  },
];

/**
 * [GET /dev/notification]
 *
 * { email: STRING }
 *
 * Get notification by email
 *
 */

const getNotification = async (id) => {
  const endpointDomain = process.env.REACT_APP_API_DOMAIN;

  return get(API(endpointDomain, `dev/notification`, { player_id: id }));
};

export default getNotification;
