import clsx from 'clsx';
import useUI from 'store/ui';
import LoadingSVG from './LoadingSVG';

const P = (props) => {
  const { className, children } = props;
  return (
    <p {...props} className={clsx('px-4 py-2 rounded cursor-pointer text-center', className)}>
      {children}
    </p>
  );
};

const Basic = (props) => {
  const { className, children } = props;
  return (
    <button {...props} type="button" className={clsx('px-4 py-2 rounded', className)}>
      {children}
    </button>
  );
};

const LoadingButton = (props) => {
  const { state } = useUI();

  const { loading } = state;

  const { className, children, loaderClassName } = props;

  if (loading)
    return (
      <div className={clsx('relative', loaderClassName)}>
        <div className="absolute w-full h-full flex justify-center items-center">
          <LoadingSVG size="w-6 h-6" />
        </div>
        <button
          {...props}
          disabled
          type="button"
          className={clsx('px-4 py-2 rounded opacity-40', className)}
        >
          {children}
        </button>
      </div>
    );
  return (
    <button {...props} type="button" className={clsx('px-4 py-2 rounded', className)}>
      {children}
    </button>
  );
};

const Buttons = { Basic, P, LoadingButton };

export default Buttons;
