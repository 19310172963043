import Card from './Card';

export default function NotificationList({ list }) {
  if (!list || list.length === 0)
    return (
      <div className="h-full flex flex-col justify-center items-center ">
        <p className="font-semibold text-xl mb-2">No Notification Yet</p>
        <p className="text-gray-500">You have no notification right now</p>
        <p className="text-gray-500">Come back later</p>
      </div>
    );

  return (
    <div className="pt-14 h-full overflow-y-auto flex flex-col space-y-4">
      {list?.map((item, index) => (
        <Card key={`notice-${index}`} notification={item} />
      ))}
    </div>
  );
}
