import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from 'routes';
import withAppProviders from './withAppProviders';
import './App.css';

const router = createBrowserRouter(routes);

function App() {
  return <RouterProvider router={router} />;
}

export default withAppProviders(App)();
