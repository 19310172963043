const NotificationReducer = (state, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATION': {
      return { ...state, notificationList: action.payload };
    }

    default:
      throw new Error();
  }
};

export default NotificationReducer;
