import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { googleLogout } from '@react-oauth/google';
import getPlayerData from 'api/player/getPlayerData';
import LoadingSVG from 'components/element/LoadingSVG';
import { isError } from 'functions/tool';
import { checkGoogleSignIn } from 'functions/user';

import UserReducer from './reducer';
import getFavorite from 'api/favorite/getFavorite';

const initialState = { user: undefined, favoriteList: undefined };

export const UserContext = createContext();

export function UserContextProvider(props) {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const setPlayerData = useCallback((googleData) => {
    getPlayerData({ email: googleData.email })
      .then((data) => {
        if (isError(data)) throw data;

        dispatch({ type: 'SET_USER', payload: { status: 'user', ...googleData, ...data } });
      })
      .catch((error) => {
        if (error.status === 500) {
          dispatch({ type: 'SET_USER', payload: { status: 'error' } });
          return;
        }
        dispatch({ type: 'SET_USER', payload: { status: 'guest', ...googleData } });
      });
  }, []);

  const getFavoriteList = useCallback((id) => {
    if (!id) return;
    getFavorite(id)
      .then((favoriteList) => {
        if (isError(favoriteList)) throw favoriteList;

        dispatch({ type: 'SET_FAVORITE', payload: favoriteList });
      })
      .catch(() => {
        dispatch({ type: 'SET_FAVORITE', payload: [] });
      });
  }, []);

  useEffect(() => {
    if (!state?.user) return;

    getFavoriteList(state?.user.id);
  }, [state?.user, getFavoriteList]);

  useEffect(() => {
    if (state.user) return;

    const googleUserInfo = checkGoogleSignIn();
    if (!googleUserInfo) {
      dispatch({ type: 'SET_USER', payload: { status: 'log-out' } });
      return;
    }

    setPlayerData(googleUserInfo);
  }, [setPlayerData, state?.user]);

  const setUser = (user) => {
    dispatch({ type: 'SET_USER', payload: user });
  };

  const setLogout = () => {
    googleLogout();
    localStorage.removeItem('user');
    dispatch({ type: 'SET_USER', payload: 'log-out' });
    window.open('/', '_self');
  };

  const value = useMemo(
    () => ({
      state,
      setUser,
      setLogout,
      setPlayerData,
      getFavoriteList,
    }),
    [setPlayerData, getFavoriteList, state]
  );

  if (!state?.user?.status)
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingSVG />
      </div>
    );

  return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
}

export default function useUser() {
  const context = useContext(UserContext);
  if (context === null) {
    throw new Error('wrap parent component');
  }
  return context;
}
