import LoadingSVG from 'components/element/LoadingSVG';
import GoogleLoginButton from 'components/GoogleLoginButton';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useUI from 'store/ui';
import useUser from 'store/user';

function SignIn() {
  const navigate = useNavigate();

  const { notify } = useUI();

  const { state, setLogout } = useUser(); // Store Google profile info

  const { user } = state;

  const userAgent = window.navigator.userAgent;

  const isInApp =
    userAgent.includes('Mobile') &&
    (userAgent.includes('iPhone') || userAgent.includes('iPad')) &&
    !userAgent.includes('Safari');

  useEffect(() => {
    if (isInApp) window.location.href = 'x-safari-https://pocketpro.org/';
  }, [isInApp]);

  const userStatusCheck = useCallback(() => {
    if (!user) return;

    if (user.status === 'error') {
      notify.on({
        type: 'error',
        title: 'Oops! Something went wrong',
        message: `Please try again later.`,
        action: () => {
          setLogout();
        },
      });
    }

    if (user.status === 'guest') navigate('/sign-up');

    if (user.status === 'user') navigate('/board');
  }, [navigate, notify, setLogout, user]);

  useEffect(() => {
    userStatusCheck();
  }, [userStatusCheck]);

  if (isInApp) return null;

  return (
    <div className="h-full flex flex-col">
      <div className="h-3/5 flex justify-center items-center">
        <h1 className="text-2xl">Welcome to PocketPro</h1>
      </div>

      <div className="h-2/5 flex items-center justify-center">
        {user?.status === 'log-out' && <GoogleLoginButton />}

        {user?.status !== 'log-out' && <LoadingSVG size="w-10 h-10" />}
      </div>
    </div>
  );
}

export default SignIn;
