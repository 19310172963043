const DataReducer = (state, action) => {
  switch (action.type) {
    case 'SET_POOL_HALL': {
      return { ...state, poolHalls: action.payload };
    }

    default:
      throw new Error();
  }
};

export default DataReducer;
