import { useState } from 'react';
import clsx from 'clsx';
import ClearIcon from 'components/icons/ClearIcon';
import BellIcon from 'components/icons/BellIcon';
import useNotification from 'store/notification';
import NotificationList from './List';

const mock = [
  {
    notification_id: '0949cebe-d679-4a26-b32b-49475aa8c09d',
    type: 'match_verification',
    notification_body: {
      match_id: 'aab633df-fc77-42f1-a9a0-8e5781efb113',
      message: 'Confirm Match: You scored 2-1 against sherin0202 (Sherin Test Roby).',
    },
    status: 'UNREAD',
  },
];

export default function NotificationDrawer({ button }) {
  const { state, checkNotification } = useNotification();

  const notifications = state?.notificationList || [];

  const hasNotifications = !!notifications.find((item) => item.type === 'match_verification');

  const [open, setOpen] = useState(false);

  const openDrawerHandler = () => {
    // reload notifications
    checkNotification();
    setOpen(true);
  };

  return (
    <>
      <button type="button" onClick={openDrawerHandler}>
        <p className="relative">
          {hasNotifications && (
            <span className="w-2 h-2 rounded-full bg-red-500 absolute top-0 right-0" />
          )}
          <BellIcon
            className={clsx('size-6', hasNotifications ? 'text-gray-800' : 'text-gray-300')}
          />
        </p>
      </button>

      <div
        className={clsx(
          'fixed top-0 right-0 z-10 h-screen p-4 bg-white w-full transition-transform duration-300',
          open ? 'shadow-2xl' : '-translate-y-full shadow-none'
        )}
      >
        <div className="absolute w-full top-0 left-0 p-5 flex items-center justify-between bg-white z-10">
          <p className="text-2xl font-bold">Notification</p>

          <button
            type="button"
            onClick={() => setOpen(false)}
            className="text-gray-400 bg-transparent hover:text-gray-900"
          >
            <ClearIcon.Basic className="size-6" />
            <span className="sr-only">Close menu</span>
          </button>
        </div>

        <NotificationList list={notifications} />
      </div>
    </>
  );
}
