import postScoreConfirm from 'api/scores/postScoreConfirm';
import Buttons from 'components/element/Buttons';
import { errorNotify, isError } from 'functions/tool';
import useNotification from 'store/notification';
import useUI from 'store/ui';

export default function MatchVerification({ notification }) {
  const { notify, loader } = useUI();

  const { checkNotification } = useNotification();

  const content = notification.notification_body;

  const confirmHandler = (action) => {
    loader.on();
    postScoreConfirm({
      matchId: content.match_id,
      notificationId: notification.notification_id,
      action,
    })
      .then((result) => {
        if (isError(result)) throw result;

        notify.on({ type: 'ok', title: '', message: `The match confirmation has been ${action}!` });
        checkNotification();
      })
      .catch((error) => {
        errorNotify(notify, error);
      })
      .finally(() => loader.off());
  };

  return (
    <div className="w-full border border-black p-2 bg-white">
      <p>{content.message}</p>

      <div className="w-full my-2 flex justify-center items-center space-x-4">
        <Buttons.LoadingButton
          type="button"
          className="bg-white text-black border border-black px-2 py-1"
          onClick={() => confirmHandler('rejected')}
        >
          Reject
        </Buttons.LoadingButton>

        <Buttons.LoadingButton
          type="button"
          className="bg-black text-white border border-black px-2 py-1"
          onClick={() => confirmHandler('approved')}
        >
          Approve
        </Buttons.LoadingButton>
      </div>
    </div>
  );
}
