const CueIcon = ({ className = 'size-6' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={className}
      viewBox="0 0 504.002 504.002"
    >
      <g>
        <g>
          <path
            d="M501.884,463.385L20.168,3.393c-4.464-4.464-12.248-4.464-16.708,0C1.228,5.625,0,8.589,0,11.745s1.228,6.12,3.392,8.288
                    l451.592,472.916c0.068,0.084,0.092,0.18,0.172,0.26c0.06,0.064,0.14,0.08,0.208,0.136l8.148,8.536
                    c0.948,0.944,2.656,2.076,5.436,2.076c6.776,0,15.88-6.684,22.128-12.94C500.652,481.453,507.716,469.217,501.884,463.385z"
          />
        </g>
      </g>
    </svg>
  );
};

export default CueIcon;
