import clsx from 'clsx';
import './style.css';

export default function Modal({ open, onClose, isCloseButton = true, children, className }) {
  return (
    <div
      className={clsx(
        'w-full h-screen bg-black-10 absolute top-0 left-0 px-10 flex justify-center items-center z-50',
        open ? 'backdrop' : 'backdrop-off',
        className
      )}
    >
      <div
        className={clsx(
          'dialog w-full max-w-xs relative p-6 bg-white rounded-lg shadow-lg',
          open ? 'showing' : 'closing'
        )}
      >
        {isCloseButton && (
          <button className="close-button" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-gray-700 hover:text-gray-900"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        )}

        {children}
      </div>
    </div>
  );
}
