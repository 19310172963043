import { API, post } from 'api/base';

const mock = {
  email: 'sherinlin1738@gmail.com',
  name: 'Sherin Lin',
  first_name: 'Sherin',
  last_name: 'Lin',
  username: 'sherin-test',
  pool_hall: 'Kou Hoaloha',
  player_level: 'SB',
  rating: 0,
  matches_played: 0,
};

/**
 * [POST /dev/accounts]
 *
 * { name: STRING,
 *   given_name: STRING,
 *   family_name: STRING,
 *   email: STRING,
 *   username: STRING,
 *   pool_hall: STRING,
 *   player_level: STRING
 *  }
 *
 * Create a new player
 *
 */

const postPlayerData = (query) => {
  const endpointDomain = process.env.REACT_APP_API_DOMAIN;
  // const token = getAuthToken();

  const { name, email, username, pool_hall, player_level } = query;

  // await mockAwait(500);
  // return mock;

  return post(API(endpointDomain, 'dev/account'), {
    name,
    email,
    username,
    pool_hall_name: pool_hall,
    player_level,
  });
};

export default postPlayerData;
