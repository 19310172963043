import { jwtDecode } from 'jwt-decode';

export const checkGoogleSignIn = () => {
  const credential = getAuthToken();
  if (!credential) return false;
  const decoded = jwtDecode(credential);
  return decoded;
};

const prohibitedWords = ['admin', 'support', 'system'];

export function validateUsername(username) {
  // 1. Length: 3-15 characters
  if (username.length < 3 || username.length > 15)
    return 'Username must be between 3 and 15 characters long.';

  // 2. Allowed characters: letters, numbers, underscores (_), hyphens (-), or dots (.)
  for (let i = 0; i < username.length; i++) {
    const char = username[i];
    if (!/[a-zA-Z0-9._-]/.test(char))
      return 'Username can only contain letters, numbers, underscores, hyphens, or dots.';
  }

  // 3. No special characters at start or end
  if (/[^a-zA-Z0-9]/.test(username[0]) || /[^a-zA-Z0-9]/.test(username[username.length - 1]))
    return 'Username cannot start or end with a special character.';

  // 4. Prohibited words or phrases: 'admin', 'support', 'system'
  for (const word of prohibitedWords) {
    if (username.toLowerCase().includes(word)) return `Username cannot contain the word '${word}'.`;
  }

  // 5. No whitespace
  if (/\s/.test(username)) return 'Username cannot contain spaces.';

  // 6. No sequential or repetitive characters (like aaa, 111, abc123)
  for (let i = 0; i < username.length - 2; i++)
    if (username[i] === username[i + 1] && username[i] === username[i + 2]) {
      return 'Username cannot have three or more consecutive repeating characters.';
    }

  // 7. No double periods, underscores, or hyphens
  if (/(\.\.|__|--)/.test(username))
    return 'Username cannot contain consecutive special characters like .., __, or --.';

  return true; // Passes all checks
}

export function getAuthToken() {
  const storedUser = localStorage.getItem('user');

  if (storedUser) {
    try {
      const user = JSON.parse(storedUser);

      if (user.credential && user.expire > new Date().getTime()) {
        return user.credential;
      }
    } catch (error) {
      console.error('Error parsing user from local storage:', error);
      localStorage.removeItem('user');
    }
  }
  return null;
}
